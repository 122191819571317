/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Box, Typography, Link as MuiLink } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useProfileByProfileIdQuery } from "queries/useProfileByProfileIdQuery";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { AuthFormWrapper } from "components/authentication/AuthFormWrapper";
import { LoginForm } from "components/authentication/login";

import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";

const Login: React.FC = () => {
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const router = useRouter();
  const { data: profile } = useProfileByProfileIdQuery({
    variables: { profileId: user?.profile_id ?? "" },
  });

  React.useEffect(() => {
    if (user?.first_login && !profile?.profileByProfileId.plan) {
      router.push(ROUTES.REGISTER_PLAN_SELECTION);
    }
    if (user?.first_login === false) {
      router.push(ROUTES.DASHBOARD);
    }
  }, [user, router, profile?.profileByProfileId.plan]);

  return (
    <AuthFormWrapper title="Login">
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <LoginForm />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          columnGap: 0.5,
          mt: 3,
        }}
      >
        <Typography
          color="textSecondary"
          variant="body1"
          style={{ fontSize: "1rem" }}
        >
          {i18n.t("login.no_account") as string}
        </Typography>
        <Link href={ROUTES.AUTH_CREATE_ACCOUNT} passHref>
          <MuiLink sx={{ p: 0 }} style={{ fontSize: "1rem" }}>
            {i18n.t("login.sign_up") as string}
          </MuiLink>
        </Link>
      </Box>
    </AuthFormWrapper>
  );
};
export default Login;
