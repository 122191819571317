import { OutlinedTextFieldProps, TextField } from "@mui/material";
import { Field, FieldProps } from "formik";
import * as React from "react";

interface FormikTextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  name: string;
}

export const FormikTextField: React.FC<FormikTextFieldProps> = (props) => (
  <Field name={props.name}>
    {({ field, meta }: FieldProps<string>) => (
      <TextField
        error={Boolean(meta.touched && meta.error)}
        helperText={meta.touched && meta.error}
        variant="outlined"
        {...field}
        {...props}
      />
    )}
  </Field>
);
