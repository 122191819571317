import { useQuery, gql, QueryHookOptions } from "@apollo/client";

import { Profile } from "types/Profile";

export interface ProfileQueryData {
  profileByProfileId: Profile;
}

export interface ProfileQueryVars {
  profileId: string;
}

export const PROFILE_BY_PROFILE_ID_QUERY = gql`
  query GetProfileByProfileId($profileId: String!) {
    profileByProfileId(profileId: $profileId) {
      profile_id
      firstName
      lastName
      organization
      email
      subscribed
      registration_date
      certified
      recertify
      plan {
        plan_id
        name
        description
        max_licenses
        is_archived
        created_at
        type
        updated_at
        stripe_product_id
        billing_cycle
        trial_period_days
        prices {
          id
          currency
          unit_amount
        }
      }
      roles {
        role_id
        name
        description
        created_at
        updated_at
      }
    }
  }
`;

export const useProfileByProfileIdQuery = (
  options?: QueryHookOptions<ProfileQueryData, ProfileQueryVars>
) =>
  useQuery<ProfileQueryData, ProfileQueryVars>(
    PROFILE_BY_PROFILE_ID_QUERY,
    options
  );
