import { gql, MutationHookOptions, useMutation } from "@apollo/client";

export interface ResendConfirmationEmailData {
  resendConfirmationEmail: {
    message: string;
    success: boolean;
  };
}

export type ResendConfirmationEmailVars = {
  email: string;
};

export const RESEND_CONFIMATION_EMAIL_MUTATION = gql`
  mutation ResendConfirmationEmail($email: String!) {
    resendConfirmationEmail(email: $email) {
      message
      success
    }
  }
`;

export const useResendConfirmationEmailMutation = (
  options?: MutationHookOptions<
    ResendConfirmationEmailData,
    ResendConfirmationEmailVars
  >
) =>
  useMutation<ResendConfirmationEmailData, ResendConfirmationEmailVars>(
    RESEND_CONFIMATION_EMAIL_MUTATION,
    options
  );
